@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Lexend;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Lexend/Lexend-Regular.ttf');
}

@font-face {
  font-family: Geist;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Geist/Geist-Regular.ttf');
}

@font-face {
  font-family: GeistBold;
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Geist/Geist-Bold.ttf');
}

@font-face {
  font-family: Futura;
  src: url('../fonts/Futura.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Futura;
  src: url('../fonts/Futura.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Myriad;
  src: url('../fonts/Myriad-pro-bold.otf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Myriad;
  src: url('../fonts/Myriad-pro.otf');
  font-weight: normal;
  font-style: normal;
}

html,
body,
input {
  padding: 0;
  margin: 0;
  font-family:
    Geist,
    Lexend,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  height: 100%;
}

#__next {
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

input:focus,
input:focus-visible {
  outline: none;
}

.wrapper {
  border-radius: unset !important;
  z-index: 1 !important;
  cursor: pointer !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 300%;
  display: flex;
  justify-content: center;
  align-items: top;
  padding-top: 100px;
  background-color: rgb(0 0 0 / 90%);
}

.modal-wrapper {
  width: 600px;
  height: 300px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 100px;
}

.modal {
  background: white;
  border-radius: 15px;
  padding: 15px;
}

.modal-body {
  padding-top: 1px;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  font-size: 25px;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #0063e1;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.loader.small {
  width: 30px;
  height: 30px;
  border-width: 4px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  appearance: none;
}

::-webkit-scrollbar:horizontal {
  height: 5px;
}

::-webkit-scrollbar:vertical {
  width: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

@layer base {
  :root {
    --radius: 0.5rem;
    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
  .dark {
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}
